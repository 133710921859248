<template>
  <div>
    <NuxtLayout class="new-order" name="data">
      2
    </NuxtLayout>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  setup() {
    return {}
  }
})
</script>

<style lang="scss">
</style>
