import { default as balanceQaPywToU55Meta } from "/app/pages/balance.vue?macro=true";
import { default as creativesBaSyqVZAZyMeta } from "/app/pages/creatives.vue?macro=true";
import { default as depositLK2Y2mAC0KMeta } from "/app/pages/deposit.vue?macro=true";
import { default as _91id_93MTc9cEKfEoMeta } from "/app/pages/group/telegram/[id].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as newsWdQv0BaSvYMeta } from "/app/pages/news.vue?macro=true";
import { default as _1pSUAdLJcxRMeta } from "/app/pages/order/1.vue?macro=true";
import { default as _2ON8T3lCyd0Meta } from "/app/pages/order/2.vue?macro=true";
import { default as _3aqSAkQooZ3Meta } from "/app/pages/order/3.vue?macro=true";
import { default as _4YcCaUTCwedMeta } from "/app/pages/order/4.vue?macro=true";
import { default as indexBpuRKxqKBqMeta } from "/app/pages/orders/[id]/index.vue?macro=true";
import { default as indexG1Ehu1sqkUMeta } from "/app/pages/orders/index.vue?macro=true";
import { default as index8EwMPkoZPEMeta } from "/app/pages/publisher/index.vue?macro=true";
import { default as payments263AuMRhvQMeta } from "/app/pages/publisher/payments.vue?macro=true";
import { default as placeWqQnxq7UhZMeta } from "/app/pages/publisher/place.vue?macro=true";
import { default as _91id_93nkis4gcu8oMeta } from "/app/pages/publisher/places/[id].vue?macro=true";
import { default as newpcwIWraKLDMeta } from "/app/pages/publisher/places/new.vue?macro=true";
import { default as publicationsonHifNIaUlMeta } from "/app/pages/publisher/publications.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as supportPKo8CaxFTuMeta } from "/app/pages/support.vue?macro=true";
import { default as confirms4Kmv58cXaMeta } from "/app/pages/user/confirm.vue?macro=true";
export default [
  {
    name: "balance",
    path: "/balance",
    component: () => import("/app/pages/balance.vue").then(m => m.default || m)
  },
  {
    name: "creatives",
    path: "/creatives",
    component: () => import("/app/pages/creatives.vue").then(m => m.default || m)
  },
  {
    name: "deposit",
    path: "/deposit",
    meta: depositLK2Y2mAC0KMeta || {},
    component: () => import("/app/pages/deposit.vue").then(m => m.default || m)
  },
  {
    name: "group-telegram-id",
    path: "/group/telegram/:id()",
    component: () => import("/app/pages/group/telegram/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/app/pages/news.vue").then(m => m.default || m)
  },
  {
    name: "order-1",
    path: "/order/1",
    component: () => import("/app/pages/order/1.vue").then(m => m.default || m)
  },
  {
    name: "order-2",
    path: "/order/2",
    component: () => import("/app/pages/order/2.vue").then(m => m.default || m)
  },
  {
    name: "order-3",
    path: "/order/3",
    component: () => import("/app/pages/order/3.vue").then(m => m.default || m)
  },
  {
    name: "order-4",
    path: "/order/4",
    component: () => import("/app/pages/order/4.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    meta: indexBpuRKxqKBqMeta || {},
    component: () => import("/app/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexG1Ehu1sqkUMeta || {},
    component: () => import("/app/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "publisher",
    path: "/publisher",
    meta: index8EwMPkoZPEMeta || {},
    component: () => import("/app/pages/publisher/index.vue").then(m => m.default || m)
  },
  {
    name: "publisher-payments",
    path: "/publisher/payments",
    meta: payments263AuMRhvQMeta || {},
    component: () => import("/app/pages/publisher/payments.vue").then(m => m.default || m)
  },
  {
    name: "publisher-place",
    path: "/publisher/place",
    meta: placeWqQnxq7UhZMeta || {},
    component: () => import("/app/pages/publisher/place.vue").then(m => m.default || m)
  },
  {
    name: "publisher-places-id",
    path: "/publisher/places/:id()",
    meta: _91id_93nkis4gcu8oMeta || {},
    component: () => import("/app/pages/publisher/places/[id].vue").then(m => m.default || m)
  },
  {
    name: "publisher-places-new",
    path: "/publisher/places/new",
    meta: newpcwIWraKLDMeta || {},
    component: () => import("/app/pages/publisher/places/new.vue").then(m => m.default || m)
  },
  {
    name: "publisher-publications",
    path: "/publisher/publications",
    meta: publicationsonHifNIaUlMeta || {},
    component: () => import("/app/pages/publisher/publications.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/app/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/app/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "user-confirm",
    path: "/user/confirm",
    component: () => import("/app/pages/user/confirm.vue").then(m => m.default || m)
  }
]